import Header from "./components/Header/Header";
import Hero from "./components/Hero/Hero";
import FeaturesGrid from "./components/FeaturesGrid";
import Pricing from "./components/Pricing";
import Footer from "./components/Footer";
import Faq from "./components/Faq";
import Testimonials from "./components/Testimonials";
import Newsletter from "./components/Newsletter";
import FeaturesDetailed from "./components/FeaturesDetailed";

import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <main className="font-['Poppins']">
      <Header />
      <Hero />
      <FeaturesDetailed />
      <FeaturesGrid />
      {/*<Testimonials />*/}
      {/*<Faq />*/}
      {/*<Newsletter />*/}
      <Footer />
    </main>
  );
}

export default App;
