import {
  CodeBracketIcon,
  CubeIcon,
  AdjustmentsHorizontalIcon,
  CloudIcon,
  ShieldCheckIcon,
  CogIcon,
} from "@heroicons/react/24/solid";
import Container from "./Container";

const FEATURES = [
  {
    icon: <CodeBracketIcon className="w-6 text-primary-100" />,
    title: "Custom Development",
    description:
      "Tailored B2C software solutions designed to meet your specific business needs and customer expectations.",
  },
  {
    icon: <CubeIcon className="w-6 text-primary-100" />,
    title: "Scalable Architecture",
    description:
      "Build robust, scalable applications that grow with your business and handle increasing user demands.",
  },
  {
    icon: <AdjustmentsHorizontalIcon className="w-6 text-primary-100" />,
    title: "User-Centric Design",
    description:
      "Create intuitive, engaging user interfaces that enhance user experience and drive customer satisfaction.",
  },
  {
    icon: <CloudIcon className="w-6 text-primary-100" />,
    title: "Cloud Integration",
    description:
      "Seamlessly integrate cloud technologies for improved performance, accessibility, and cost-efficiency.",
  },
  {
    icon: <ShieldCheckIcon className="w-6 text-primary-100" />,
    title: "Robust Security",
    description:
      "Implement advanced security measures to protect user data and ensure compliance with industry standards.",
  },
  {
    icon: <CogIcon className="w-6 text-primary-100" />,
    title: "Continuous Support",
    description:
      "Provide ongoing maintenance and support to keep your software running smoothly and up-to-date.",
  },
];

const FeaturesGrid = () => {
  return (
    <section 
      className="border-b border-primary-900 text-white relative"
      style={{
        backgroundImage: "url('/section3.png')",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="absolute inset-0 bg-black opacity-70"></div>
      <Container xpadded ypadded>
        <div className="relative z-10">
          <div className="mb-16 max-md:flex flex-col items-center w-full">
            <div className="max-w-screen-md">
              <h2 className="mb-4 text-4xl lg:text-6xl tracking-tight font-semibold">
                Empowering B2C Success Through Software
              </h2>
              <p className="sm:text-xl">
                We specialize in creating bespoke B2C software solutions and products, leveraging cutting-edge technology to drive innovation and deliver exceptional user experiences.
              </p>
            </div>
          </div>
          <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
            {FEATURES.map(({ icon, title, description }, index) => {
              return (
                <div key={index} className="flex">
                  <div className="flex shrink-0 items-center justify-center mb-4 w-12 h-12 rounded-full bg-primary-900 mr-5">
                    {icon}
                  </div>
                  <div className="flex flex-col">
                    <h3 className="mb-2 text-xl font-bold">{title}</h3>
                    <p>{description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default FeaturesGrid;