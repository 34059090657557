import { useState, useEffect } from "react";
import { EnvelopeIcon, UserIcon, BuildingOfficeIcon, CodeBracketIcon } from "@heroicons/react/24/solid";
import Container from "./Container";
import { toast } from 'react-toastify';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    companyName: "",
    projectType: "",
    description: "",
    email: ""
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Simulate form submission
    toast.success("Thank you for your inquiry. We'll be in touch soon!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    // Reset form
    setFormData({
      fullName: "",
      companyName: "",
      projectType: "",
      description: "",
      email: ""
    });
  };

  return (
    <section className="border-b border-primary-900 text-primary-900">
      <Container xpadded ypadded>
        <div className="mx-auto max-w-screen-md sm:text-center">
          <h2 className="mb-4 text-4xl lg:text-6xl tracking-tight font-semibold">
            Let's Discuss Your B2C Software Needs
          </h2>
          <p className="mx-auto mb-8 max-w-2xl font-light md:mb-12 sm:text-xl">
            We specialize in creating bespoke B2C software solutions tailored to your unique business requirements.
          </p>
          <form onSubmit={handleSubmit}>
            <h1 className="text-3xl font-bold mb-6">Tell us about your project</h1>
            <div className="space-y-4 max-w-screen-sm mx-auto">
              <div className="relative">
                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <UserIcon className="w-5" />
                </div>
                <input
                  className="block p-3 pl-10 w-full text-sm text-primary-900 border border-primary-900 bg-gray-50 rounded-lg placeholder-primary-900"
                  placeholder="Full Name"
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="relative">
                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <BuildingOfficeIcon className="w-5" />
                </div>
                <input
                  className="block p-3 pl-10 w-full text-sm text-primary-900 border border-primary-900 bg-gray-50 rounded-lg placeholder-primary-900"
                  placeholder="Company Name"
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="relative">
                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <CodeBracketIcon className="w-5" />
                </div>
                <input
                  className="block p-3 pl-10 w-full text-sm text-primary-900 border border-primary-900 bg-gray-50 rounded-lg placeholder-primary-900"
                  placeholder="Project Type (e.g., Mobile App, Web Platform)"
                  type="text"
                  name="projectType"
                  value={formData.projectType}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="relative">
                <textarea
                  className="block p-3 w-full text-sm text-primary-900 border border-primary-900 bg-gray-50 rounded-lg placeholder-primary-900"
                  placeholder="Describe your project and objectives"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  rows="5"
                  required
                  style={{ resize: "vertical" }}
                />
              </div>
              <div className="relative">
                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <EnvelopeIcon className="w-5 text-primary-900" />
                </div>
                <input
                  className="block p-3 pl-10 w-full text-sm text-primary-900 border border-primary-900 bg-gray-50 rounded-lg placeholder-primary-900"
                  placeholder="Email address"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="py-3 px-5 w-full text-sm font-medium text-center text-primary-100 rounded-lg cursor-pointer bg-primary-900 border border-primary-900 hover:bg-primary-800"
                >
                  Submit Inquiry
                </button>
              </div>
            </div>
          </form>
        </div>
      </Container>
    </section>
  );
};

export default ContactForm;