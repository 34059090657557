const HeroHeadlines = () => {
  return (
    <div className="relative z-10 text-center">
      <h1 className="font-bold text-white text-5xl sm:text-6xl lg:text-7xl xl:text-8xl mb-6 leading-tight">
        Oculus Labs
      </h1>
      <p className="text-xl sm:text-2xl text-white font-light mb-8 mx-auto max-w-2xl">
        Building bespoke B2C software products
      </p>
      <div className="absolute inset-0 bg-black opacity-30 blur-lg -z-10"></div>
    </div>
  );
};

export default HeroHeadlines;