import { createBrowserRouter } from 'react-router-dom';
import Landing from './App';
import Contact from './components/Contact';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/contact",
    element: <Contact />,
  }
]);

export default router;