const HeaderButtons = () => {
  return (
    <div className="space-x-8 font-medium">
      <a
        href="/contact"
        className="bg-primary-900 py-3 px-5 rounded-md text-primary-100"
      >
        Work with us
      </a>
    </div>
  );
};

export default HeaderButtons;
