import Header from "../Header/Header";
import Footer from "../Footer";
import Newsletter from "../Newsletter";
function About() {
  return (
    <main className="font-['Poppins']">
      <Header />
      <Newsletter/>
      <Footer />
    </main>
  );
}

export default About;
