import Container from "./Container";

const TESTIMONIALS = [
  {
    name: "Bonnie Green",
    job: "Marketing Manager at Wyze",
    title: "Exceptional Growth on TikTok",
    avatar:
      "https://randomuser.me/api/portraits/women/44.jpg",
    quote: (
      <span>
        "Working with this TikTok Shop Agency has been a game-changer for our brand. In just a few months, we've seen a significant increase in our follower count and engagement rates. Their creative content strategies and targeted advertising have helped us reach a wider audience and drive more sales through our TikTok Shop. I'm beyond impressed with the results and the professionalism of their team."
      </span>
    ),
  },
  {
    name: "Roberta Casas",
    job: "Founder at Yoges",
    title: "Revolutionized Our Online Sales",
    avatar:
      "https://randomuser.me/api/portraits/women/43.jpg",
    quote: (
      <>
        "The TikTok Shop Agency has revolutionized our online sales strategy. Their expertise in TikTok marketing has allowed us to tap into a new market and significantly boost our sales. The team is incredibly knowledgeable and always on top of the latest trends, ensuring our content stays fresh and engaging. I'm thrilled with the growth we've experienced and highly recommend their services."
      </>
    ),
  },
  {
    name: "Jese Leos",
    title: "A Game-Changer for ",
    avatar:
      "https://randomuser.me/api/portraits/men/44.jpg",
    job: "CEO at Dude Wipes",
    quote: (
      <>
        "Partnering with this TikTok Shop Agency has been a game-changer for our brand. Their strategic approach to content creation and advertising has not only increased our visibility on TikTok but also driven significant traffic to our shop. The team is creative, responsive, and truly understands how to leverage the platform to achieve business goals. We couldn't be happier with the results!"
      </>
    ),
  },
  {
    name: "Joseph McFall",
    title: "Outstanding Service and Results",
    avatar:
      "https://randomuser.me/api/portraits/men/43.jpg",
    job: "Director of E-commerce at ZyberVR",
    quote: (
      <>
        "This TikTok Shop Agency has provided outstanding service and results for our e-commerce business. Their tailored strategies and in-depth analytics have helped us optimize our TikTok Shop and connect with our target audience more effectively. The team's dedication and expertise have been instrumental in our success on the platform. I highly recommend them to any business looking to thrive on TikTok."
      </>
    ),
  },
];


const TestimonialBlock = ({ title, quote, avatar, name, job, color }) => {
  return (
    <figure
      className={`flex flex-col justify-center items-center text-center p-8 my-2 lg:m-5 rounded-lg ${color}`}
    >
      <blockquote className="mx-auto mb-8 max-w-2xl">
        <h3 className="text-lg font-semibold">{title}</h3>
        <p className="my-4" style={{ whiteSpace: "pre-wrap" }}>
          {quote}
        </p>
      </blockquote>
      <figcaption className="flex justify-center items-center space-x-3">
        <img src={avatar} alt="placeholder" className="w-9 h-9 rounded-full" />
        <div className="font-medium text-left">
          <div>{name}</div>
          <div className="text-sm font-light">{job}</div>
        </div>
      </figcaption>
    </figure>
  );
};

const Testimonials = () => {
  return (
    <section className="bg-primary-900 text-primary-100">
      <Container xpadded ypadded>
        <div className="text-center">
          <div className="mx-auto max-w-screen-sm">
            <h2 className="mb-4 text-4xl lg:text-6xl tracking-tight font-semibold">
              Testimonials
            </h2>
            <p className="mb-8 font-light lg:mb-16 sm:text-xl">
              Hear directly from companies we've worked with!
            </p>
          </div>
          <div className="grid mb-8 lg:mb-12 lg:grid-cols-2 text-primary-900">
            {TESTIMONIALS.map((testimonial, index) => {
              return (
                <TestimonialBlock
                  {...testimonial}
                  color={index % 3 ? "bg-amber-100" : "bg-primary-100"}
                />
              );
            })}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Testimonials;
