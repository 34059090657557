import Logo from "../Logo";
import HeaderNav from "./HeaderNav";
import HeaderButtons from "./HeaderButtons";
import HeaderMobileMenu from "./HeaderMobileMenu";
import Container from "../Container";

export const NAV_ITEMS = [
  /*{
    title: "TikTok Ads",
    href: "#",
    description: "Learn more about what we do",
  },*/
  {
    href: "/",
    title: "Home",
    description: "See our features",
  }
];

const Header = () => {
  return (
    <header>
      <nav className="bg-primary-100 py-10 border-b border-primary-900 text-primary-900">
        <Container xpadded>
          <div className="flex justify-between items-center">
            <Logo />
            <HeaderNav />
            <div className="flex items-center max-lg:hidden">
              <HeaderButtons />
            </div>
            <div className="lg:hidden z-10 mt-1">
              <HeaderMobileMenu />
            </div>
          </div>
        </Container>
      </nav>
    </header>
  );
};

export default Header;
