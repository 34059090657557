import { CheckCircleIcon } from "@heroicons/react/24/solid";
import Container from "./Container";

const FEATURES = [
  {
    title: "Tailored B2C Software Solutions",
    description:
      "We create custom software solutions that cater specifically to your business needs and your customers' expectations.",
    checklist: [
      "User-centric design",
      "Scalable architecture",
      "Continuous integration and deployment",
      "Cross-platform compatibility",
    ],
    footer:
      "Our bespoke solutions empower businesses to stand out in the competitive B2C market.",
  },
  {
    title: "Innovative B2C Product Development",
    description:
      "We transform your ideas into cutting-edge B2C products that captivate and retain customers.",
    checklist: [
      "Agile development methodology",
      "Advanced analytics integration",
      "AI and machine learning capabilities",
      "Robust security measures",
    ],
    footer:
      "Stay ahead of the curve with our forward-thinking approach to product development.",
  },
];

const FeatureChecklist = ({
  title,
  footer,
  checklist,
  description,
}) => {
  return (
    <div className="bg-white bg-opacity-80 backdrop-blur-md rounded-lg p-8 mb-12">
      <h2 className="mb-4 text-4xl lg:text-5xl font-semibold tracking-tight">{title}</h2>
      <p className="font-light lg:text-xl mb-6">{description}</p>
      <ul className="space-y-5 my-7">
        {checklist.map((item, index) => {
          return (
            <li key={index} className="flex space-x-3">
              <CheckCircleIcon className="w-5 flex-shrink-0 text-primary-900" />
              <span className="text-base font-medium leading-tight">
                {item}
              </span>
            </li>
          );
        })}
      </ul>
      <p className="lg:text-lg">{footer}</p>
    </div>
  );
};

const FeaturesDetailed = () => {
  return (
    <section 
      className="border-b border-primary-900 text-primary-900 bg-cover bg-center"
      style={{
        backgroundImage: `url('section2.png')`,
        backgroundAttachment: 'fixed'
      }}
    >
      <div className="backdrop-blur-sm bg-white/30">
        <Container xpadded ypadded>
          <div className="grid md:grid-cols-2 gap-8">
            {FEATURES.map((feature, index) => {
              return <FeatureChecklist key={index} {...feature} />;
            })}
          </div>
        </Container>
      </div>
    </section>
  );
};

export default FeaturesDetailed;