import Container from "../Container";
import HeroCTA from "./HeroCTA";
import HeroHeadlines from "./HeroHeadlines";
import HeroImage from "./HeroImage";

const Hero = () => {
  return (
    <section
      className="text-primary-900 relative border-b border-primary-900 overflow-hidden"
      style={{
        backgroundImage: "url('/header.png')",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Container xpadded>
        <div className="max-lg:py-10 lg:h-[80vh] flex">
          <div className="flex items-center justify-center min-h-[70vh] px-4 sm:px-6 lg:px-8">
            <HeroHeadlines />
          </div>
          {/*<HeroImage />*/}
        </div>
      </Container>
    </section>
  );
};

export default Hero;